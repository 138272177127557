import Location from "./Location";

export interface OnboardingCode {
  id: number;
  code: string;
  locationIds: string[];
  locations: Location.Type[];
  organizationId: string;
  lockerEmailNotificationsEnabled: boolean;
  emailNotificationsEnabled: boolean;
  mobileNotificationsEnabled: boolean;
  lockerMobileNotificationsEnabled: boolean;
  smsNotificationsEnabled: boolean;
  smsLockerNotificationsEnabled: boolean;
  createdAt: Date;
  usedCount: number;
  tagName: string;
  tagColor: string;
}

export function onboardingCodeToFeatureList(code: OnboardingCode): {
  locker: string[];
  system: string[];
} {
  const lockerFeatures: string[] = [];
  const systemFeatures: string[] = [];

  if (code.smsLockerNotificationsEnabled) lockerFeatures.push("sms");
  if (code.lockerEmailNotificationsEnabled) lockerFeatures.push("email");
  if (code.lockerMobileNotificationsEnabled) lockerFeatures.push("push");

  if (code.smsNotificationsEnabled) systemFeatures.push("sms");
  if (code.emailNotificationsEnabled) systemFeatures.push("email");
  if (code.mobileNotificationsEnabled) systemFeatures.push("push");

  return {
    locker: lockerFeatures,
    system: systemFeatures,
  };
}
