import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import "./style.scss";
import Input from "../Input";
import { motion } from "framer-motion";
import { useSession } from "../../hooks/SessionProvider";
import { Button } from "../ui/button";
import { verifyOnboardingCodeRequest } from "../../services/onboarding-codes/onboarding-codes.service";
import { useRouter } from "../../hooks/useRouter";

interface OnboardingCodeDetails {
  code: string;
}

const SignUpOnboardingCodeForm = ({
  onComplete,
}: {
  onComplete: () => void;
}) => {
  const router = useRouter();
  const { t: getTranslationByLabel } = useTranslation();
  const [error, setError] = useState<string | undefined>();
  const { setOnboardingCode } = useSession();
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<OnboardingCodeDetails>({ mode: "all" });

  const submit = async (data: OnboardingCodeDetails) => {
    try {
      await verifyOnboardingCodeRequest(data.code);
      setError(undefined);
      setOnboardingCode(data.code);
      onComplete();
    } catch (e: any) {
      console.warn(e.message);
      setError("Invalid code");
    }
  };

  return (
    <motion.div
      initial={{ x: 400 }}
      animate={{ x: 0 }}
      exit={{ x: -400 }}
      transition={{
        type: "spring",
        duration: 0.2,
        stiffness: 450,
        damping: 35,
      }}
    >
      <div className="flex flex-col items-stretch">
        <div className="flex flex-col flex-grow py-8 items-stretch">
          <h2 className="text-2xl font-semibold text-mvl-primary mb-6">
            {getTranslationByLabel("auth-onboarding-code-title")}
          </h2>
          <form onSubmit={handleSubmit(submit)}>
            <div className="mb-11 flex flex-col items-stretch">
              <Controller
                name="code"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    label={getTranslationByLabel("auth-onboarding-code-label")}
                    type="text"
                    containerStyle={{ marginBottom: "16px" }}
                    {...field}
                  />
                )}
              />

              {error && (
                <p className="bg-red-50 p-3  rounded-md text-destructive text-center mb-3 text-lg">
                  {error}
                </p>
              )}
            </div>
            <div className="flex flex-col w-full gap-4 items-center justify-center ">
              <Button
                className="w-full max-w-xs"
                disabled={!isDirty || !isValid}
              >
                {getTranslationByLabel("auth-onboarding-code-button")}{" "}
              </Button>
              <Button
                variant="link"
                className="w-full justify-center pb-6"
                onClick={() => router.push("/login")}
              >
                {getTranslationByLabel("auth-login-prompt")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </motion.div>
  );
};

export default SignUpOnboardingCodeForm;
