import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment-timezone";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import Snackbar from "./components/Snackbar";
import { SELECTED_LANGUAGE_KEY } from "./constants";
import { DEFAULT_LANGUAGE } from "./i18n/config";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import NotFound from "./pages/NotFound";
import { ThemeProvider } from "styled-components";
import { buildTheme } from "./styles/theme";
import { BASE_PATH, LOGIN_PATH, SIGNUP_PATH } from "./routes";
import store from "./store";
import { Col } from "./styles/layout";
import { SessionProvider } from "./hooks/SessionProvider";
import Home from "./pages/Home";
import { Toaster } from "react-hot-toast";
import { configureAxios } from "./api";

moment.tz.setDefault("Europe/Paris");
configureAxios();

const STAGE = process.env.REACT_APP_STAGE;

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem(SELECTED_LANGUAGE_KEY);
    if (!savedLanguage) {
      const browserLanguage = navigator.language.split("-")[0];
      const languageToUse = i18n.languages.includes(browserLanguage)
        ? browserLanguage
        : DEFAULT_LANGUAGE;
      localStorage.setItem(SELECTED_LANGUAGE_KEY, languageToUse);
      i18n.changeLanguage(languageToUse);
    } else if (savedLanguage !== i18n.language) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const theme = buildTheme("indigo", false);

  useEffect(() => {
    document.title = `Marvelock Portal ${STAGE !== "prod" ? `[${STAGE}]` : ""}`;
    return () => {};
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <SessionProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Toaster position="bottom-right" />
            <Col className="root" bg="bgBase">
              <Switch>
                <Route exact path={LOGIN_PATH} component={Login} />
                <Route exact path={SIGNUP_PATH} component={Signup} />
                <Route path={BASE_PATH} component={Home} />
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
              <Snackbar />
            </Col>
          </LocalizationProvider>
        </SessionProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
