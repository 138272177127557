import { Backdrop, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router-dom";
import { useUserAccess } from "../../hooks/useUserAccess";
import {
  APPLICATION_PATH,
  HALLNEWS_PATH,
  LOCATIONS_PATH,
  LOCKERS_PATH,
  ONBOARDING_CODES_PATH,
  ORGANISATIONS_PATH,
  REPORTS_PATH,
  SETTINGS_PATH,
  USERS_PATH,
} from "../../routes";
import Locations from "../Locations";
import Lockers from "../Lockers";
import Reports from "../Reports";
import Users from "../Users";
import "./style.scss";
import HallnewsRoutes from "../Hallnews/HallnewsRoutes";
import Organizations from "../Organisations";
import { useSession } from "../../hooks/SessionProvider";
import PageWrapper from "../../common/PageWrapper";
import Settings from "../Settings";
import { Toaster } from "sonner";
import { Loader2 } from "lucide-react";
import OnboardingCodesPage from "../OnboardingCodes";

const Home = () => {
  const theme = useTheme();
  const { i18n } = useTranslation();
  const access = useUserAccess();
  const { user, isLoading } = useSession();

  useEffect(() => {
    if (user) {
      i18n.changeLanguage(user.userConfiguration?.language);
    }
  }, [i18n, user]);

  console.log(access);

  return (
    <div className="home-container h-screen w-screen">
      <Toaster />
      <PageWrapper className=" w-full h-full">
        {isLoading && (
          <Backdrop open={isLoading} style={{ zIndex: theme.zIndex.drawer }}>
            <div className="text-accent">
              <Loader2 size={80} className="animate-spin" />
            </div>
          </Backdrop>
        )}
        {!isLoading && !!access && (
          <div className="h-full p-6">
            <Switch>
              {access.hasUsersAccess && (
                <Route exact path={USERS_PATH} component={Users} />
              )}
              {access.hasLocationsAccess && (
                <Route exact path={LOCATIONS_PATH} component={Locations} />
              )}
              {access.hasOrganisationsAccess && (
                <Route
                  exact
                  path={ORGANISATIONS_PATH}
                  component={Organizations}
                />
              )}

              {access.hasOnboardingCodesAccess && (
                <Route
                  exact
                  path={ONBOARDING_CODES_PATH}
                  component={OnboardingCodesPage}
                />
              )}

              {access.hasLockersAccess && (
                <Route exact path={LOCKERS_PATH} component={Lockers} />
              )}
              {(access.hasReportsAccess || access.hasServiceOrdersAccess) && (
                <Route exact path={REPORTS_PATH} component={Reports} />
              )}
              <Route exact path={SETTINGS_PATH} component={Settings} />
              <Route exact path={APPLICATION_PATH}>
                {user && (
                  <Redirect
                    to={
                      user.notificationFeatureEnabled
                        ? HALLNEWS_PATH
                        : REPORTS_PATH
                    }
                  />
                )}
              </Route>
              {access.hasNotificationsAccess && <HallnewsRoutes />}
            </Switch>
          </div>
        )}
      </PageWrapper>
    </div>
  );
};

export default Home;
