import { fetcher } from "../../lib/apiFetch";
import useSWR from "swr";
import { routes } from "./onboarding-codes.service";
import { QueryOnboardingCodeDTO } from "./onboarding-codes.dto";
import { OnboardingCode } from "../../entities/OnboardingCode";

interface Props extends QueryOnboardingCodeDTO {}

export function useOnboardingCodes(query: Props) {
  const { data, error, mutate } = useSWR(
    () => ({
      url: routes.list,
      queryParam: {
        ...query,
      },
    }),
    fetcher<any>,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  return {
    data: (data?.content ?? []) as OnboardingCode[],
    page: data?.number,
    hasMore: data?.last,
    total: data?.totalElements,
    pages: data?.totalPages,
    isLoading: !error && !data,
    isError: error,
    refresh: mutate,
  };
}
