import React from "react";
import { BoxProps } from "@mui/material/Box";
import Nav from "../components/Nav";
import TopBar from "../components/TopBar";

const PageWrapper: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <div className="flex w-screen bg-background overflow-hidden h-screen">
      <Nav />
      <div className="flex flex-col items-stretch w-full overflow-x-hidden">
        <TopBar />
        <div className="flex-grow overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};

export default PageWrapper;
