import { Pagination } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLocationFilters } from "../hooks/useLocationFilters";
import { usePagination } from "../hooks/usePagination";
import { ServiceOrderReport } from "../entities";
import { format } from "date-fns";
import CopyToClipboardButton from "../common/CopyToClipboardButton";
import { ClipLoader } from "react-spinners";
import { Badge } from "../components/ui/badge";
import { cn } from "../lib/utils";
import { useServiceOrdersReport } from "../hooks/useServiceOrdersReport";
import ItemsFilter from "../components/ItemsFilter";
import { useTranslation } from "react-i18next";
import { PageContent, PageHeader, PageTitle } from "../components/ui/page";
import Dropdown from "../components/Dropdown";
import { RangePicker } from "../components/ui/range-picker";
import { DateRange } from "react-day-picker";
import { Button } from "../components/ui/button";
import { exportServiceOrders } from "../services/service-orders.service";
import { useSession } from "../hooks/SessionProvider";

const ITEMS_PER_PAGE = 8;

const Reports = () => {
  const routerLocation = useLocation();
  const { t: getTranslationByLabel } = useTranslation();
  const { user } = useSession();
  const [page, setPage] = usePagination();
  const { sortDirection, organization, location } = useLocationFilters();
  const [status, setStatus] = useState<string | undefined>();
  const [range, setRange] = useState<DateRange | undefined>();
  const query = useMemo(() => {
    const query: any = { sortDirection: "DESC", sort: "effective_date" };
    if (organization) query.organizationId = organization;
    if (sortDirection) query.sortDirection = sortDirection;
    if (location) query.locationId = location;
    query.status = status;
    if (range) {
      query.from = range?.from?.toISOString();
      query.to = range?.to?.toISOString();
    }
    return query;
  }, [organization, sortDirection, location, status, range]);

  const { reports, isLoading, pages } = useServiceOrdersReport(
    (page ?? 0) - 1,
    ITEMS_PER_PAGE,
    query
  );

  const handleExport = useCallback(async () => {
    const response = await exportServiceOrders(query);
    const blob = new Blob([response as any], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "service-orders.csv"); // or any other filename you want
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [query]);

  return (
    <>
      <PageHeader>
        <PageTitle className="flex justify-between items-center">
          {getTranslationByLabel("reports-menu")}
          {user?.role !== "RESIDENT" && (
            <Button variant={"default"} onClick={handleExport}>
              Export
            </Button>
          )}
        </PageTitle>
        <div className="flex items-center space-x-4 justify-between">
          <div>
            {user?.role !== "RESIDENT" && (
              <div className="flex items-center space-x-2">
                <ItemsFilter showLocations />
                <Dropdown
                  items={[
                    {
                      label: getTranslationByLabel("delivered"),
                      value: "inactive",
                    },
                    {
                      label: getTranslationByLabel("available"),
                      value: "active",
                    },
                  ]}
                  value={status}
                  onChange={(v) => setStatus(v)}
                  triggerClassList="w-64"
                  placeholder="Status"
                />
                <RangePicker value={range} onChange={(v) => setRange(v)} />
              </div>
            )}
          </div>
          <Pagination
            onChange={(_, page) => {
              // Decrease and increase with 1, because the API has zero based pagination filter
              setPage(page, routerLocation.search);
            }}
            page={page ?? 1}
            count={pages}
            variant="outlined"
            color="primary"
            shape="rounded"
          />
        </div>
      </PageHeader>
      <PageContent>
        <table className="min-w-full text-left text-sm font-light">
          <thead className="border-b border-border font-medium">
            <tr className="pb-4 text-muted-foreground">
              <th className="py-4">Type</th>
              <th className="py-4 w-32">Status</th>
              <th className="py-4 w-48">Date</th>
              <th className="py-4">Location</th>
              <th className="py-4">Casier</th>
              <th className="py-4">Utilisateur</th>
              <th className="py-4">Récupéré par</th>
              <th className="py-4">Code d'accès</th>
              <th className="py-4"></th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report: ServiceOrderReport.Type) => {
              const status =
                report.state === "active" ? "available" : "delivered";
              // Check if effectiveDate is more than 3 days in the past
              const isLate =
                status === "available" &&
                new Date(report.effectiveDate).getTime() <
                  Date.now() - 3 * 24 * 60 * 60 * 1000;

              return (
                <tr
                  key={report.id}
                  className="cursor-pointer rounded-sm border-b border-border  transition-all duration-200 ease-in-out  last:border-b-0 "
                >
                  <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                    <Badge className="lowercase">{report.type}</Badge>
                  </td>
                  <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                    <Badge
                      className={cn("capitalize", {
                        "bg-success text-success-foreground":
                          status === "delivered",
                        "bg-destructive text-destructive-foreground": isLate,
                      })}
                    >
                      {getTranslationByLabel(isLate ? "attention" : status)}
                    </Badge>
                  </td>
                  <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                    {format(new Date(report.effectiveDate), "dd/MM/yyyy HH:mm")}
                  </td>
                  <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                    <div className="text-xs text-muted-foreground">
                      {report.organisation.name}
                    </div>
                    <div>{`${report.location.name}`}</div>
                  </td>
                  <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                    <div>{`${report.locker.name}`}</div>
                    <div className="text-xs text-muted-foreground">
                      {`${getTranslationByLabel("door")} ${
                        report.bins[0].number
                      }`}
                    </div>
                  </td>

                  <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                    {report.user
                      ? `${report.user?.firstName} ${report.user?.lastName}`
                      : "Deleted user"}
                  </td>
                  <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                    {report.pickupBy}
                  </td>
                  <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                    {report.accessCode}
                  </td>

                  <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light "></td>
                  <td className="">
                    <div className="flex items-center justify-end space-x-4">
                      <CopyToClipboardButton valueToCopy={report.id} />
                    </div>
                  </td>
                </tr>
              );
            })}
            {reports.length === 0 && !isLoading && (
              <tr>
                <td colSpan={8} className="py-4 text-center">
                  Aucun résultat
                </td>
              </tr>
            )}
            {reports.length === 0 && isLoading && (
              <tr>
                <td colSpan={8} className="py-6 text-center">
                  <ClipLoader size="medium" color="secondary" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </PageContent>
    </>
  );
};

export default Reports;
