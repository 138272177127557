import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { SignUpDTO, createWithCode } from "../../services/users";
import Input from "../Input";
import { Button } from "../ui/button";
import { useSession } from "../../hooks/SessionProvider";
import Dropdown from "../Dropdown";

const SignupForm = ({ onComplete }: { onComplete: () => void }) => {
  const { t: getTranslationByLabel } = useTranslation();
  const [error, setError] = useState<string | undefined>();
  const { setEmail, onboardingCode } = useSession();
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<SignUpDTO>({ mode: "all" });

  const submit = async (data: SignUpDTO) => {
    try {
      setError(undefined);
      if (!onboardingCode) {
        throw new Error("Onboarding code is required");
      }
      data.code = onboardingCode;
      await createWithCode(data);
      setEmail(data.email);
      onComplete();
    } catch (e: any) {
      console.error(e);
      setError(getTranslationByLabel("auth-signup-error"));
    }
  };

  return (
    <motion.div
      initial={{ x: 0 }}
      animate={{ x: 0 }}
      exit={{ x: -400 }}
      layout
      transition={{
        type: "spring",
        duration: 0.2,
        stiffness: 450,
        damping: 35,
      }}
    >
      <div className="flex flex-col items-stretch max-w-md">
        <div className="flex-grow py-8 flex flex-col items-stretch">
          <h2 className="text-2xl font-semibold text-mvl-primary mb-6">
            {getTranslationByLabel("auth-signup-title")}
          </h2>
          <form
            onSubmit={handleSubmit(submit)}
            className="grid grid-cols-3 gap-2"
          >
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: inputField, fieldState: { error } }) => (
                <Input
                  label={getTranslationByLabel(`auth-signup-firstName`)}
                  type="text"
                  containerStyle={{ marginBottom: "16px" }}
                  {...inputField}
                />
              )}
            />
            <Controller
              name="middleName"
              control={control}
              defaultValue=""
              render={({ field: inputField, fieldState: { error } }) => (
                <Input
                  label={getTranslationByLabel(`auth-signup-middleName`)}
                  type="text"
                  containerStyle={{ marginBottom: "16px" }}
                  {...inputField}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: inputField, fieldState: { error } }) => (
                <Input
                  label={getTranslationByLabel(`auth-signup-lastName`)}
                  type="text"
                  containerStyle={{ marginBottom: "16px" }}
                  {...inputField}
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: inputField, fieldState: { error } }) => (
                <Input
                  label={getTranslationByLabel(`auth-signup-email`)}
                  type="email"
                  containerStyle={{
                    marginBottom: "16px",
                    gridColumn: "span 2",
                  }}
                  {...inputField}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern: {
                  value: /^\+\d{1,3}\d{4,14}$/,
                  message: getTranslationByLabel("invalid-phone-number"),
                },
              }}
              render={({ field: inputField, fieldState: { error } }) => (
                <Input
                  label={getTranslationByLabel(`auth-signup-phone`)}
                  type="text"
                  containerStyle={{ marginBottom: "16px" }}
                  error={error ? error.message : null}
                  {...inputField}
                />
              )}
            />
            <Controller
              name="flatNumber"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: inputField, fieldState: { error } }) => (
                <Input
                  label={getTranslationByLabel(`auth-signup-flatNumber`)}
                  type="text"
                  containerStyle={{ marginBottom: "16px" }}
                  {...inputField}
                />
              )}
            />

            <Controller
              name="language"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <Dropdown
                  items={[
                    { label: "EN", value: "en" },
                    { label: "FR", value: "fr" },
                  ]}
                  label={getTranslationByLabel(`auth-signup-language`)}
                  value={field.value}
                  wrapperCss="w-full"
                  triggerClassList="h-10 "
                  onChange={(v) => field.onChange(v)}
                />
              )}
            />

            <div className="col-span-full flex flex-col items-stretch pt-6 gap-4">
              {error && <p className="text-xs text-red-500 mb-4">{error}</p>}
              <div className="flex justify-center w-full">
                <Button
                  className="w-full max-w-xs"
                  disabled={!isDirty || !isValid}
                >
                  {getTranslationByLabel("auth-signup-button")}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </motion.div>
  );
};

export default SignupForm;
