import { Box, Pagination, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { sendHealthCheck, sendHealthCheckForAllLockers } from "../api/lockers";
import LockerDialog from "../components/LockerDialog";
import { useAppDispatch, useAppSelector } from "../hooks";
import { usePagination } from "../hooks/usePagination";
import {
  getLockers,
  selectIsLoading,
  selectItems,
  selectPages,
} from "../store/lockersSlice";
import { useLocation } from "react-router-dom";
import { Locker } from "../entities";
import { Badge } from "../components/ui/badge";
import { cn } from "../lib/utils";
import CopyToClipboardButton from "../common/CopyToClipboardButton";
import { Button } from "../components/ui/button";
import { ClipLoader } from "react-spinners";
import { LucidePlus } from "lucide-react";
import toast from "react-hot-toast";
import { useLockerState } from "../hooks/useLockerState";
import { PageContent, PageHeader, PageTitle } from "../components/ui/page";

const Lockers = () => {
  const dispatch = useAppDispatch();
  const { t: getTranslationByLabel } = useTranslation();
  const location = useLocation();
  const isLoading = useAppSelector(selectIsLoading);
  const items = useAppSelector(selectItems);
  const pages = useAppSelector(selectPages);
  const [page, setPage] = usePagination();
  const [dialogOpened, setDialogOpened] = useState(false);
  const [editId, setEditId] = useState("");
  const checkRef = useRef<boolean>(false);

  useEffect(() => {
    // Decrease and increase with 1, because the API has zero based pagination filter
    if (page) {
      dispatch(getLockers(page - 1));
    }
  }, [dispatch, page]);

  const handleHealthCheck = async () => {
    await sendHealthCheckForAllLockers();
    toast.success("Health check sent.");
  };

  useEffect(() => {
    if (!checkRef.current) {
      handleHealthCheck();
      checkRef.current = true;
    }
  }, []);

  return (
    <>
      <PageHeader>
        <PageTitle>{getTranslationByLabel("lockers-menu")}</PageTitle>
        <div className="flex items-center justify-between py-5">
          <Button variant="outline" size="sm" onClick={handleHealthCheck}>
            {getTranslationByLabel("send-health-check")}
          </Button>
          <div className="flex items-center space-x-4">
            <Pagination
              onChange={(_, page) => {
                // Decrease and increase with 1, because the API has zero based pagination filter
                setPage(page, location.search);
              }}
              page={page ?? 1}
              count={pages}
              variant="outlined"
              color="primary"
              shape="rounded"
            />
            <Button
              onClick={() => setDialogOpened(true)}
              variant="default"
              className="space-x-2"
            >
              <LucidePlus size={16} />
              <span>{getTranslationByLabel("create-new")}</span>
            </Button>
          </div>
        </div>
      </PageHeader>
      <PageContent>
        <>
          <table className="min-w-full text-left text-sm font-light">
            <thead className="border-b border-border font-medium">
              <tr className="pb-4 text-muted-foreground">
                <th className="py-4 w-32">Status</th>
                <th className="py-4 ">Nom</th>
                <th className="py-4">Location</th>
                <th className="py-4"></th>
              </tr>
            </thead>
            <tbody>
              {items.map((item: Locker.Type) => (
                <LockerRow
                  key={item.id}
                  locker={item}
                  onEdit={() => {
                    setEditId(item.id);
                    setDialogOpened(true);
                  }}
                />
              ))}
              {items.length === 0 && !isLoading && (
                <tr>
                  <td colSpan={8} className="py-4 text-center">
                    Aucun casier trouvé
                  </td>
                </tr>
              )}
              {items.length === 0 && isLoading && (
                <tr>
                  <td colSpan={8} className="py-6 text-center">
                    <ClipLoader size="medium" color="secondary" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {!items.length && !isLoading && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flex={1}
            >
              <Typography variant="h3" color="textSecondary">
                {getTranslationByLabel("no-items")}
              </Typography>
            </Box>
          )}
        </>
      </PageContent>

      <LockerDialog
        open={dialogOpened}
        id={editId}
        onClose={(shouldRefetch?: boolean) => {
          setEditId("");
          setDialogOpened(false);
          if (shouldRefetch) {
            dispatch(getLockers(page ? page - 1 : 0));
          }
        }}
      />
    </>
  );
};

export default Lockers;

interface LockerProps {
  locker: Locker.Type;
  onEdit: () => void;
}
const LockerRow = ({ locker, onEdit }: LockerProps) => {
  const { state } = useLockerState(locker.id, locker);
  const { t: getTranslationByLabel } = useTranslation();

  return (
    <tr className="cursor-pointer rounded-sm border-b border-border  transition-all duration-200 ease-in-out  last:border-b-0 ">
      <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
        <Badge
          className={cn({
            "bg-success text-success-foreground": state,
          })}
        >
          {state ? "active" : "inactive"}
        </Badge>
      </td>
      <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
        {locker.name}
      </td>
      <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
        {locker.location.name}
      </td>
      <td className="">
        <div className="flex items-center justify-end space-x-4">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              sendHealthCheck(locker.id);
              toast.success("Health check sent.");
            }}
          >
            {"Check"}
          </Button>
          <Button size="sm" onClick={onEdit}>
            {getTranslationByLabel("edit")}
          </Button>
          <CopyToClipboardButton valueToCopy={locker.id} />
        </div>
      </td>
    </tr>
  );
};
