export const BASE_PATH = "/";
export const APPLICATION_PATH = "/app";
export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/signup";
export const TERMS_AND_CONDITIONS_PATH = "/terms-and-conditions";
export const APP_TERMS_AND_CONDITIONS_PATH = `${APPLICATION_PATH}${TERMS_AND_CONDITIONS_PATH}`;
export const PRIVACY_POLICY_PATH = "/privacy-policy";
export const APP_PRIVACY_POLICY_PATH = `${APPLICATION_PATH}${PRIVACY_POLICY_PATH}`;
export const ABOUT_US_PATH = `/about-us`;
export const APPLICATION_ABOUT_US_PATH = `${APPLICATION_PATH}/about-us`;
export const FORGOT_PASSWORD_PATH = "/forgot-password";
export const HALLNEWS_PATH = `${APPLICATION_PATH}/hallnews`;
export const NOTIFICATIONS_PATH = `${APPLICATION_PATH}/notifications`;
export const LOCKERS_PATH = `${APPLICATION_PATH}/lockers`;
export const USERS_PATH = `${APPLICATION_PATH}/users`;
export const LOCATIONS_PATH = `${APPLICATION_PATH}/locations`;
export const ORGANISATIONS_PATH = `${APPLICATION_PATH}/organisations`;
export const REPORTS_PATH = `${APPLICATION_PATH}/reports`;
export const SETTINGS_PATH = `${APPLICATION_PATH}/settings`;
export const ONBOARDING_CODES_PATH = `${APPLICATION_PATH}/onboarding-codes`;
