import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import OTPForm from "../components/AuthForm/OTPForm";
import { useSession } from "../hooks/SessionProvider";
import { APPLICATION_PATH } from "../routes";
import { useRouter } from "../hooks/useRouter";
import SignupForm from "../components/AuthForm/SignupForm";
import SignUpOnboardingCodeForm from "../components/AuthForm/OnboardingCodeForm";

const SignUp = () => {
  const router = useRouter();

  const { authenticated } = useSession();
  const [step, setStep] = useState<"code" | "details" | "otp">("code");

  useEffect(() => {
    if (authenticated) {
      router.push(APPLICATION_PATH);
    }
  }, [authenticated, router]);

  return (
    <div
      className="flex flex-col justify-center items-center w-screen h-screen bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${require("../assets/background.jpg")})` }}
    >
      <div className="flex flex-col justify-start items-stretch bg-white shadow-md w-fit rounded-md overflow-hidden pt-11 px-6">
        <div className="flex flex-col items-center mb-6">
          <img
            src="/logo192.png"
            alt="Logo"
            className="h-14 w-full object-contain mb-4"
          />
          <img
            src="/logo-marveLock.png"
            alt="MarveLock Logo"
            className="h-14 w-full object-contain"
          />
        </div>
        <AnimatePresence>
          {step === "code" && (
            <SignUpOnboardingCodeForm
              onComplete={() => {
                setStep("details");
              }}
            />
          )}
          {step === "details" && (
            <SignupForm
              onComplete={() => {
                setStep("otp");
              }}
            />
          )}
          {step === "otp" && <OTPForm onComplete={() => {}} />}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default SignUp;
