import Organisation from "../../entities/Organisation";
import { useOrganisations } from "../../hooks/useOrganisations";
import NewOrganisationDialog from "./__containers/NewOrganisationDialog";
import { useState } from "react";
import ModalOrganisationUpdate from "../../components/Modal/ModalOrganisationUpdate";
import { useTranslation } from "react-i18next";
import { PageContent, PageHeader, PageTitle } from "../../components/ui/page";
import { format } from "date-fns";
import { ClipLoader } from "react-spinners";
import { Button } from "../../components/ui/button";

const Organizations = () => {
  const { t: getTranslationByLabel } = useTranslation();

  const { organisations, isLoading, refresh } = useOrganisations();
  const [showNewOrgaDialog, setShowNewOrgaDialog] = useState<boolean>(false);
  const [selectedOrganisation, setSelectedOrganisation] =
    useState<null | Organisation.Type>(null);
  return (
    <>
      <PageHeader>
        <div className="flex items-center w-full flex-row justify-between">
          <PageTitle>{getTranslationByLabel("organisations-menu")}</PageTitle>
          <Button variant="default" onClick={() => setShowNewOrgaDialog(true)}>
            {getTranslationByLabel("add")}
          </Button>
        </div>
      </PageHeader>
      <PageContent>
        <table className="min-w-full text-left text-sm font-light">
          <thead className="border-b border-border font-medium">
            <tr className="pb-4 text-muted-foreground">
              <th className="py-4 w-32">Ajouté le</th>
              <th className="py-4">Nom</th>
              <th className="py-4">Description</th>
              <th className="py-4">Locations</th>
              <th className="py-4"></th>
            </tr>
          </thead>
          <tbody>
            {organisations.map((organisation: Organisation.Type) => (
              <tr
                key={organisation.id}
                className="cursor-pointer rounded-sm border-b border-border  transition-all duration-200 ease-in-out  last:border-b-0 "
              >
                <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                  {format(new Date(organisation.createdAt!), "dd/MM/yyyy")}
                </td>
                <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                  {organisation.name}
                </td>

                <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                  {organisation.description}
                </td>
                <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                  {organisation.locations?.map((l) => l.name).join(", ")}
                </td>
                <td className="">
                  <div className="flex items-center justify-end space-x-4">
                    <Button
                      size="sm"
                      onClick={() => {
                        setSelectedOrganisation(organisation);
                      }}
                    >
                      {getTranslationByLabel("edit")}
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            {organisations.length === 0 && !isLoading && (
              <tr>
                <td colSpan={8} className="py-4 text-center">
                  Aucun emplacement trouvé
                </td>
              </tr>
            )}
            {organisations.length === 0 && isLoading && (
              <tr>
                <td colSpan={8} className="py-6 text-center">
                  <ClipLoader size="medium" color="secondary" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </PageContent>
      <NewOrganisationDialog
        open={showNewOrgaDialog}
        onClose={() => {
          setShowNewOrgaDialog(false);
          refresh();
        }}
      />
      {selectedOrganisation && (
        <ModalOrganisationUpdate
          open={!!selectedOrganisation}
          onClose={() => {
            setSelectedOrganisation(null);
            refresh();
          }}
          organisation={selectedOrganisation}
        />
      )}
    </>
  );
};

export default Organizations;
