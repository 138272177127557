import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContent, PageHeader, PageTitle } from "../../components/ui/page";
import { Button } from "../../components/ui/button";
import { useOnboardingCodes } from "../../services/onboarding-codes/onboarding-codes.hook";
import { ModalOnboardingCode } from "../../components/onboarding-codes/modal-onboarding-code";
import {
  OnboardingCode,
  onboardingCodeToFeatureList,
} from "../../entities/OnboardingCode";
import { LucidePlus } from "lucide-react";
import { Pagination } from "@mui/material";
import { usePagination } from "../../hooks/usePagination";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import CopyToClipboardButton from "../../common/CopyToClipboardButton";
import { ClipLoader } from "react-spinners";
import { useSession } from "../../hooks/SessionProvider";

const OnboardingCodesPage = () => {
  const routerLocation = useLocation();

  const { t: getTranslationByLabel } = useTranslation();
  const [showNewOnboardingCodeDialog, setShowNewOnboardingCodeDialog] =
    useState<boolean>(false);
  const [selectedOnboardingCode, setSelectedOnboardingCode] = useState<
    OnboardingCode | undefined
  >(undefined);
  const [page, setPage] = usePagination();
  const { user } = useSession();

  const { data, isLoading, pages, refresh } = useOnboardingCodes({
    page: 0,
    items: 20,
  });
  return (
    <>
      <PageHeader>
        <div className="flex justify-between">
          <PageTitle>
            {getTranslationByLabel("onboarding-codes-menu")}
          </PageTitle>
          {user?.role === "MARVELOCK_ADMIN" && (
            <Button
              onClick={() => setShowNewOnboardingCodeDialog(true)}
              variant="default"
              className="space-x-2"
            >
              <LucidePlus size={16} />
              <span>{getTranslationByLabel("create-new")}</span>
            </Button>
          )}
        </div>
        <div className="space-y-2 ">
          <div className="flex items-center space-x-4 justify-between ">
            <div className="flex items-center space-x-2"></div>

            <div className="flex items-center space-x-4">
              <Pagination
                onChange={(_, page) => {
                  setPage(page, routerLocation.search);
                }}
                page={page ?? 1}
                count={pages}
                variant="outlined"
                color="primary"
                shape="rounded"
              />
            </div>
          </div>
        </div>
      </PageHeader>
      <PageContent>
        <table className="min-w-full text-left text-sm font-light">
          <thead className="border-b border-border font-medium">
            <tr className="pb-4 text-muted-foreground">
              <th className="py-4 w-32">Tag</th>
              <th className="py-4 w-32">Date</th>
              <th className="py-4 w-48">Code</th>
              <th className="py-4 w-80">Emplacement(s)</th>
              <th className="py-4">Settings</th>
              <th className="py-4">Usages</th>
              <th className="py-4"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((onboardingCode) => {
              const { locker, system } =
                onboardingCodeToFeatureList(onboardingCode);
              return (
                <tr
                  key={onboardingCode.id}
                  className="cursor-pointer rounded-sm border-b border-border  transition-all duration-200 ease-in-out  last:border-b-0 "
                >
                  <td>
                    <div
                      className="flex items-center justify-center px-3 py-1 w-fit text-xs rounded-full"
                      style={{ backgroundColor: onboardingCode.tagColor }}
                    >
                      {onboardingCode.tagName}
                    </div>
                  </td>
                  <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                    {format(new Date(onboardingCode.createdAt), "dd/MM/yyyy")}
                  </td>
                  <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                    {onboardingCode.code}
                  </td>

                  <td className="text-foreground  whitespace-nowrap text-ellipsis overflow-hidden max-w-44 px-1 py-5 text-sm font-light ">
                    {onboardingCode.locations.map((l) => l.name).join(", ")}
                  </td>
                  <td className="text-foreground  whitespace-nowrap text-ellipsis overflow-hidden max-w-44 px-1 py-5 text-sm font-light ">
                    <div className="flex gap-1">
                      <div className="text-xs font-semibold">Locker:</div>
                      <div className="text-xs font-light">
                        {locker.length > 0 ? locker.join(", ") : "none"}
                      </div>
                    </div>
                    <div className="flex gap-1">
                      <div className="text-xs font-semibold">System:</div>
                      <div className="text-xs font-light">
                        {system.length > 0 ? system.join(", ") : "none"}
                      </div>
                    </div>
                  </td>
                  <td className="text-foreground  whitespace-nowrap text-ellipsis overflow-hidden max-w-44 px-1 py-5 text-sm font-light ">
                    {onboardingCode.usedCount}
                  </td>

                  <td className="">
                    <div className="flex items-center justify-end space-x-4">
                      <CopyToClipboardButton
                        valueToCopy={onboardingCode.code}
                      />
                      {user?.role === "MARVELOCK_ADMIN" && (
                        <Button
                          size="sm"
                          onClick={() => {
                            setSelectedOnboardingCode(onboardingCode);
                          }}
                        >
                          {getTranslationByLabel("edit")}
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
            {data.length === 0 && !isLoading && (
              <tr>
                <td colSpan={8} className="py-4 text-center">
                  Aucun code d'onboarding trouvé
                </td>
              </tr>
            )}
            {data.length === 0 && isLoading && (
              <tr>
                <td colSpan={8} className="py-6 text-center">
                  <ClipLoader size="medium" color="secondary" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </PageContent>

      <ModalOnboardingCode
        open={showNewOnboardingCodeDialog || !!selectedOnboardingCode}
        onClose={() => {
          setShowNewOnboardingCodeDialog(false);
          setSelectedOnboardingCode(undefined);
        }}
        onUpdate={refresh}
        onboardingCode={selectedOnboardingCode}
      />
    </>
  );
};

export default OnboardingCodesPage;
