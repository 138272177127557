import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import "./style.scss";
import Input from "../Input";
import { motion } from "framer-motion";
import { useSession } from "../../hooks/SessionProvider";
import { Button } from "../ui/button";

interface OTPDetails {
  otp: string;
}

const OTPForm = ({ onComplete }: { onComplete: () => void }) => {
  const { t: getTranslationByLabel } = useTranslation();
  const [error, setError] = useState<string | undefined>();
  const { signIn } = useSession();
  const { control, handleSubmit } = useForm<OTPDetails>({
    mode: "all",
  });

  const submit = async (data: OTPDetails) => {
    try {
      setError(undefined);
      await signIn(data.otp);
      // router.push({ pathname: "/signin/auth", query: { email: data.email } });
    } catch (e: any) {
      console.warn(e.message);
      setError("Invalid or expired codes");
    }
  };
  return (
    <motion.div
      initial={{ x: 400 }}
      animate={{ x: 0 }}
      exit={{ x: -400 }}
      transition={{
        type: "spring",
        duration: 0.2,
        stiffness: 450,
        damping: 35,
      }}
    >
      <div className="flex flex-col items-stretch max-w-xs">
        <div className="flex flex-col flex-grow py-8 items-stretch">
          <h2 className="text-2xl font-semibold text-mvl-primary mb-6">
            {getTranslationByLabel("auth-otp-title")}
          </h2>
          <form onSubmit={handleSubmit(submit)}>
            <div className="mb-11 flex flex-col items-stretch">
              <Controller
                name="otp"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    label="Code"
                    type="text"
                    containerStyle={{ marginBottom: "16px" }}
                    {...field}
                  />
                )}
              />

              {error && (
                <p className="text-danger-textLow text-center mb-3 text-lg">
                  {error}
                </p>
              )}
            </div>

            {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
            <Button className="w-full">
              {getTranslationByLabel("auth-otp-button")}
            </Button>
          </form>
        </div>
      </div>
    </motion.div>
  );
};
export default OTPForm;
