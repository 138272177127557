import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";

import Input from "../../../components/Input";
import { useState } from "react";

import { create } from "../../../services/organisations";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/ui/button";
interface OwnProps {
  id?: string;
  open: boolean;
  onClose: () => void;
}

const NewOrganisationDialog = ({ id, open, onClose }: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();

  const [name, setName] = useState<string | null>(null);
  const [description, setDescription] = useState<string | undefined>(undefined);

  const [error, setError] = useState<string | null>(null);

  const handleCreate = async () => {
    if (!name) {
      setError("Remplissez chaque champ");
    } else {
      try {
        const response = await create({
          name,
          description,
        });
        console.log(response);
        onClose();
      } catch (e: any) {
        setError(e.toString());
        console.error(e);
      }
    }
  };

  const handleClose = () => {
    setName(null);
    setDescription(undefined);
    setError(null);
    onClose();
  };

  return (
    <>
      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Créer une nouvelle organisation</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 pb-6">
            <Input
              label="Nom"
              value={name}
              onChange={(v: string) => {
                setError(null);
                setName(v);
              }}
              containerStyle={{ mb: "4" }}
            />
            <Input
              value={description}
              label="Description"
              onChange={(v: string) => {
                setError(null);
                setDescription(v);
              }}
              containerStyle={{ mb: "4" }}
            />
          </div>
          {error && (
            <div className="text-red-500 bg-red-100 p-3 rounded-md">
              {error}
            </div>
          )}
          <DialogFooter>
            <Button variant="default" onClick={handleCreate}>
              {getTranslationByLabel("add")}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewOrganisationDialog;
