import { useState } from "react";

import { useComponents } from "../../../hooks/useComponents";
import NewComponentDialog from "../__containers/NewComponentDialog";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/ui/button";
import { useRouter } from "../../../hooks/useRouter";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import UpdateComponentDialog from "../__containers/UpdateComponentDialog";
import HallnewsComponent from "../../../entities/HallnewsComponent";
import { useSession } from "../../../hooks/SessionProvider";

export const Components = () => {
  const { t: getTranslationByLabel } = useTranslation();
  const router = useRouter();
  const { user } = useSession();
  const { components, refresh: refreshComponents } = useComponents(0, 1000);
  const [showNewComponentModal, setShowNewComponentModal] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState<
    HallnewsComponent.Type | undefined
  >();

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center justify-between">
            <div className="font-semibold text-lg">
              {getTranslationByLabel("hallnews-components")}
            </div>
            {["MARVELOCK_ADMIN", "SITE_MANAGER"].includes(user?.role ?? "") && (
              <Button
                size="sm"
                variant="default"
                onClick={() => setShowNewComponentModal(true)}
              >
                {getTranslationByLabel("add")}
              </Button>
            )}
          </CardTitle>
        </CardHeader>
        <CardContent>
          {components.length > 0 && (
            <table className="min-w-full text-left text-sm font-light">
              <thead className="border-b border-border font-medium">
                <tr className="pb-4 text-muted-foreground">
                  <th className="py-4 w-48">Organisation</th>
                  <th className="py-4 w-48">Nom</th>
                  <th className="py-4">Messages</th>
                  <th className="py-4"></th>
                </tr>
              </thead>
              <tbody>
                {components.map((component) => {
                  const messages = component.content?.length ?? 0;
                  return (
                    <tr
                      key={component.id}
                      className="cursor-pointer rounded-sm border-b border-border  transition-all duration-200 ease-in-out  last:border-b-0 "
                    >
                      <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                        {component.organization?.name}
                      </td>
                      <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                        {component.label}
                      </td>

                      <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                        {messages}
                      </td>
                      <td>
                        <div className="flex justify-end space-x-2">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() =>
                              router.push(
                                `/app/hallnews/widgets/${component.id}`
                              )
                            }
                          >
                            {getTranslationByLabel("manage")}
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {components.length === 0 && (
            <div>
              <div className="text-sm text-muted-foreground text-center py-6">
                Consultez votre gestionnaire de site pour accéder à des widgets
              </div>
            </div>
          )}
        </CardContent>
      </Card>
      <NewComponentDialog
        open={showNewComponentModal}
        id={"newComponentModal"}
        onClose={() => {
          setShowNewComponentModal(false);
          refreshComponents();
        }}
      />
      {!!selectedComponent && (
        <UpdateComponentDialog
          open={!!selectedComponent}
          onClose={() => {
            setSelectedComponent(undefined);
            refreshComponents();
          }}
          onDeleted={refreshComponents}
          component={selectedComponent}
        />
      )}
    </>
  );
};
