import { apiFetch } from "../../lib/apiFetch";
import {
  CreateOnboardingCodeDTO,
  UpdateOnboardingCodeDTO,
} from "./onboarding-codes.dto";

const API = process.env.REACT_APP_API_URL;

export const routes = {
  list: `${API}/onboarding-codes`,
  findById: (id: number) => `${API}/onboarding-codes/${id}`,
  verifyCode: (code: string) => `${API}/onboarding-codes/${code}/verify`,

  listByOrganization: (organizationId: string) =>
    `${API}/onboarding-codes/organization/${organizationId}`,
  listByOrganizations: (organizationIds: string[]) =>
    `${API}/onboarding-codes/organizations?organizationIds=${organizationIds.join(
      ","
    )}`,
  listByIds: (codeIds: number[]) =>
    `${API}/onboarding-codes?codeIds=${codeIds.join(",")}`,
  create: `${API}/onboarding-codes`,
  update: (id: number) => `${API}/onboarding-codes/${id}`,
  delete: (id: number) => `${API}/onboarding-codes/${id}`,
};

export const createOnboardingCodeRequest = (body: CreateOnboardingCodeDTO) =>
  apiFetch({
    method: "POST",
    url: routes.create,
    body,
    authRequired: true,
  });

export const updateOnboardingCodeRequest = (
  id: number,
  data: UpdateOnboardingCodeDTO
) =>
  apiFetch({
    method: "PUT",
    url: routes.update(id),
    body: data,
    authRequired: true,
  });

export const verifyOnboardingCodeRequest = (code: string) =>
  apiFetch({
    method: "POST",
    url: routes.verifyCode(code),
    authRequired: false,
  });

export const deleteOnboardingCodeRequest = (id: number) =>
  apiFetch({
    method: "DELETE",
    url: routes.delete(id),
    authRequired: true,
  });
