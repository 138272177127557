import Box, { BoxProps } from "@mui/material/Box";
import Button from "@mui/material/Button";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { FormikHelpers, useFormik } from "formik";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteLocker, sendHealthCheck, submitLocker } from "../../api/lockers";
import DeleteConfirmationDialog from "../../common/DeleteConfirmationDialog";
import { Bin, Location, Locker } from "../../entities";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getLocations,
  selectItems,
  selectItemsCount,
} from "../../store/locationsSlice";
import { selectItemById } from "../../store/lockersSlice";
import { getCreateLockerValidationSchema } from "../../validation";
import AddBinDialog from "../AddBinDialog";
import HealthCheckDialog, { HealthCheckType } from "../HealthCheckDialog";
import BinItem from "./BinItem";
import "./style.scss";
import {
  EnumNotificationType,
  showNotification,
} from "../../store/notificationSlice";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import Dropdown from "../Dropdown";

interface FormikValues {
  lockerName: string;
  location?: Location.Type;
  bins: Partial<Bin.Type>[];
  start: moment.Moment;
  end: moment.Moment;
}

interface OwnProps {
  id?: string;
  open: boolean;
  onClose: (shouldRefetch?: boolean) => void;
}

const ITEMS_PER_PAGE = 3;

const LockerDialog = ({ id, open, onClose }: OwnProps) => {
  const dispatch = useAppDispatch();
  const { t: getTranslationByLabel } = useTranslation();
  const organization = useAppSelector((state) => state.user.self?.organization);
  const locations = useAppSelector(selectItems);
  const itemsCount = useAppSelector(selectItemsCount);
  const locker = useAppSelector((state) => selectItemById(state, id));
  const [page, setPage] = useState(1);
  const [healthCheckType, setHealthCheckType] = useState<HealthCheckType>();
  const [binDialogOpen, setBinDialogOpen] = useState(false);
  const [binToEdit, setBinToEdit] = useState<Partial<Bin.Type>>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    if (
      open &&
      (!locations.length || !itemsCount || locations.length !== itemsCount)
    ) {
      dispatch(getLocations({ page: 0, itemsPerPage: 200 }));
    }
  }, [open, locations, itemsCount, dispatch]);

  const initialFormikValues = useMemo<FormikValues>(
    () => ({
      lockerName: "",
      bins: [],
      start: moment().hour(7).minute(0),
      end: moment().hour(22).minute(0),
    }),
    []
  );

  const {
    isSubmitting,
    values,
    errors,
    touched,
    resetForm,
    dirty,
    setFieldTouched,
    submitForm,
    handleChange,
  } = useFormik<FormikValues>({
    initialValues: initialFormikValues,
    validationSchema: getCreateLockerValidationSchema(
      getTranslationByLabel("required-field"),
      getTranslationByLabel("long-string")
    ),
    onSubmit: async (
      values: FormikValues,
      { setSubmitting }: FormikHelpers<FormikValues>
    ) => {
      try {
        setSubmitting(true);
        const item: Partial<Locker.Type> = {
          name: values.lockerName,
          ...values,
          organization,
          bins: values.bins as Bin.Type[],
          reservationStartTime: values.start.hour(),
          reservationEndTime: values.end.hour(),
        };
        if (!!id) {
          item.id = id;
        }
        await submitLocker(item, !id);
        setSubmitting(false);
        onClose(true);
      } catch (error: any) {
        setSubmitting(false);
        dispatch(
          showNotification({
            text: error.response.data,
            type: EnumNotificationType.Error,
          })
        );
        console.log("Error while save locker -> ", error);
      }
    },
  });

  useEffect(() => {
    if (locker) {
      resetForm({
        values: {
          lockerName: locker.name,
          location: locker.location,
          bins: locker.bins,
          start: moment().hour(locker.reservationStartTime).minute(0),
          end: moment().hour(locker.reservationEndTime).minute(0),
        },
      });
    }
  }, [locker, resetForm]);

  useEffect(() => {
    if (!open) {
      resetForm({ values: initialFormikValues });
      setPage(1);
    }
  }, [initialFormikValues, open, resetForm]);

  const binsToDisplay = useMemo(() => {
    if (!values) {
      return [];
    }
    let startIndex = page - 1;
    if (page !== 1) {
      startIndex = ITEMS_PER_PAGE * (page - 1);
    }
    return values.bins.slice(startIndex, ITEMS_PER_PAGE * page);
  }, [values, page]);

  const handleDelete = async () => {
    console.log("Deleting");
    if (!id) {
      return;
    }
    await deleteLocker(id);
    onClose(true);
  };

  const handleSelectChange = useCallback(
    (locationId) => {
      const location = locations.find((el) => el.id === locationId);
      handleChange({
        target: {
          name: "location",
          value: location,
        },
      });
    },
    [handleChange, locations]
  );

  const binsLabel = useMemo(() => {
    const count = values.bins.length;
    let labelNumber = page * ITEMS_PER_PAGE;
    if (count < ITEMS_PER_PAGE || labelNumber > count) {
      labelNumber = count;
    }
    return `${labelNumber}/${count} ${getTranslationByLabel(
      "bins"
    ).toLowerCase()}`;
  }, [values.bins.length, page, getTranslationByLabel]);

  return (
    <>
      <Dialog open={open} onOpenChange={() => onClose(false)}>
        <DialogContent className="w-fit max-w-none">
          <DialogHeader>
            <DialogTitle>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h4">
                  {getTranslationByLabel(
                    id ? "update-locker" : "create-locker"
                  )}
                </Typography>
              </Box>
            </DialogTitle>
          </DialogHeader>
          <div className="flex flex-col ">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={2}
            >
              <Box display="flex" alignItems="flex-start">
                <Item width={250} label={getTranslationByLabel("locker-name")}>
                  <TextField
                    id="lockerName"
                    name="lockerName"
                    type="text"
                    placeholder={`${getTranslationByLabel(
                      "enter-locker-name"
                    )}...`}
                    value={values.lockerName}
                    onChange={(e) => {
                      setFieldTouched(e.target.name, true);
                      handleChange(e);
                    }}
                    error={touched.lockerName && Boolean(errors.lockerName)}
                    helperText={
                      (touched.lockerName && errors.lockerName) || " "
                    }
                  />
                </Item>
                <Item
                  width={200}
                  label={getTranslationByLabel("location-name")}
                >
                  <FormControl error={Boolean(errors.location)}>
                    <Dropdown
                      triggerClassList="h-14"
                      items={locations
                        .filter(
                          (loc) => loc.locationFeature?.lockerFeatureEnabled
                        )
                        .map((location) => ({
                          label: `${location.name}, ${location.building}`,
                          value: location.id,
                        }))}
                      value={values.location?.id}
                      onChange={handleSelectChange}
                    />

                    {Boolean(errors.location) && (
                      <FormHelperText>{errors.location}</FormHelperText>
                    )}
                  </FormControl>
                </Item>
                <Item width={120} label={getTranslationByLabel("start")}>
                  <TimePicker
                    value={values.start}
                    views={["hours"]}
                    onChange={(value) => {
                      if (value) {
                        handleChange({
                          target: {
                            name: "start",
                            value: value,
                          },
                        });
                        if (!values.end || values.end.isBefore(value)) {
                          handleChange({
                            target: {
                              name: "end",
                              value: value,
                            },
                          });
                        }
                      }
                    }}
                    ampm={false}
                    minutesStep={5}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Item>
                <Item width={120} label={getTranslationByLabel("end")}>
                  <TimePicker
                    value={values.end}
                    views={["hours"]}
                    minTime={values.start}
                    onChange={(value) => {
                      if (value) {
                        handleChange({
                          target: {
                            name: "end",
                            value: value,
                          },
                        });
                      }
                    }}
                    ampm={false}
                    minutesStep={5}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Item>
              </Box>
              <Box display="flex" minWidth="350">
                {!!id && (
                  <Button
                    fullWidth
                    sx={{
                      width: 185,
                      height: 60,
                      marginRight: "16px",
                    }}
                    variant="outlined"
                    onClick={() => {
                      sendHealthCheck(id);
                      setHealthCheckType(HealthCheckType.SINGLE);
                    }}
                  >
                    {getTranslationByLabel("send-health-check")}
                  </Button>
                )}
                <Button
                  fullWidth
                  sx={{
                    width: 185,
                    height: 60,
                  }}
                  variant="contained"
                  onClick={() => {
                    setBinDialogOpen(true);
                  }}
                >
                  {getTranslationByLabel("add-new-bin")}
                </Button>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              {!!values.bins.length ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  width="100%"
                >
                  <div className="text-right text-foreground text-sm font-semibold w-full">
                    {binsLabel}
                  </div>
                  {binsToDisplay.map((el) => (
                    <BinItem
                      key={el.id}
                      item={el}
                      onEdit={() => {
                        setBinDialogOpen(true);
                        setBinToEdit(el);
                      }}
                    />
                  ))}
                  <Pagination
                    onChange={(_, page) => {
                      setPage(page);
                    }}
                    page={page ?? 1}
                    count={Math.ceil(values.bins.length / ITEMS_PER_PAGE)}
                    variant="outlined"
                    color="primary"
                    shape="rounded"
                  />
                </Box>
              ) : (
                <>
                  <Typography
                    textAlign="center"
                    variant="h5"
                    color="textSecondary"
                  >
                    {getTranslationByLabel("no-bins")}
                  </Typography>
                  {touched.bins && Boolean(errors.bins) && (
                    <Typography
                      textAlign="center"
                      variant="caption"
                      color="error"
                    >
                      {getTranslationByLabel("please-add-bins")}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </div>
          <DialogFooter>
            {!!id && (
              <>
                <Button
                  className="button"
                  variant="text"
                  disabled={isSubmitting}
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  {getTranslationByLabel("delete")}
                </Button>
              </>
            )}
            <Button
              className="button"
              variant="contained"
              autoFocus
              disabled={isSubmitting || !dirty}
              onClick={submitForm}
            >
              {getTranslationByLabel("submit")}
            </Button>
          </DialogFooter>
        </DialogContent>

        <HealthCheckDialog
          open={!!healthCheckType}
          type={healthCheckType}
          onClose={() => {
            setHealthCheckType(undefined);
          }}
        />
        <AddBinDialog
          open={binDialogOpen}
          bin={binToEdit}
          onClose={() => {
            setBinDialogOpen(false);
            setBinToEdit(undefined);
          }}
          onSubmit={(item) => {
            const binWithSameNumber = values.bins.find(
              (el) => el.number === item.number
            );
            if (binWithSameNumber) {
              if (!binToEdit || binToEdit.number !== item.number) {
                dispatch(
                  showNotification({
                    text: getTranslationByLabel("bin-number-error"),
                    type: EnumNotificationType.Error,
                  })
                );
                return;
              }
            }
            if (binToEdit) {
              const objIndex = values.bins.findIndex(
                (obj) => obj.number === item.number
              );
              const newBins = [...values.bins];
              newBins[objIndex] = item;
              handleChange({
                target: {
                  name: "bins",
                  value: newBins,
                },
              });
            } else {
              handleChange({
                target: {
                  name: "bins",
                  value: [...values.bins, item],
                },
              });
            }
            setBinDialogOpen(false);
            setBinToEdit(undefined);
          }}
          onRemoveBin={() => {
            handleChange({
              target: {
                name: "bins",
                value: values.bins.filter(
                  (el) => el.number !== binToEdit?.number
                ),
              },
            });
            setBinDialogOpen(false);
            setBinToEdit(undefined);
          }}
        />
        <DeleteConfirmationDialog
          open={deleteDialogOpen}
          // disabled={isSubmitting}
          onConfirm={handleDelete}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
        />
      </Dialog>
    </>
  );
};

interface ItemProps extends BoxProps {
  label: string;
}

const Item: React.FC<ItemProps> = ({ children, label, ...rest }: ItemProps) => (
  <Box display="flex" flexDirection="column" mr={2} {...rest}>
    <Typography variant="caption" gutterBottom textTransform="uppercase">
      {label}
    </Typography>
    {children}
  </Box>
);

export default LockerDialog;
