import { useEffect, useState } from "react";
import { useSession } from "./SessionProvider";

interface UserAccess {
  hasServiceOrdersAccess: boolean;
  hasNotificationsAccess: boolean;
  hasLockersAccess: boolean;
  hasUsersAccess: boolean;
  hasLocationsAccess: boolean;
  hasReportsAccess: boolean;
  hasOrganisationsAccess: boolean;
  hasOnboardingCodesAccess: boolean;
}

export function useUserAccess() {
  const { user } = useSession();

  const [userAccess, setUserAccess] = useState<UserAccess>({
    hasServiceOrdersAccess: false,
    hasNotificationsAccess: false,
    hasLockersAccess: false,
    hasUsersAccess: false,
    hasLocationsAccess: false,
    hasReportsAccess: false,
    hasOrganisationsAccess: false,
    hasOnboardingCodesAccess: false,
  });

  useEffect(() => {
    if (user) {
      const hasOrganisationsAccess = user.role === "MARVELOCK_ADMIN";
      const hasNotificationsAccess =
        user.role === "MARVELOCK_ADMIN" ||
        user.role === "SITE_MANAGER" ||
        user.role === "BUILDING_MANAGER" ||
        user.role === "RESIDENT";
      const hasServiceOrdersAccess =
        user.role === "MARVELOCK_ADMIN" ||
        user.role === "SITE_MANAGER" ||
        (user.role === "RESIDENT" && user.lockerFeatureEnabled);
      const hasUsersAccess =
        user.role === "MARVELOCK_ADMIN" ||
        user.role === "SITE_MANAGER" ||
        user.role === "BUILDING_MANAGER";
      const hasOnboardingCodesAccess =
        user.role === "MARVELOCK_ADMIN" ||
        user.role === "SITE_MANAGER" ||
        user.role === "BUILDING_MANAGER";

      // Users different from MARVELOCK_ADMIN can only see the lockers
      const hasLockersAccess = user.role === "MARVELOCK_ADMIN";
      // Users different from MARVELOCK_ADMIN can only see the locations
      const hasLocationsAccess = user.role === "MARVELOCK_ADMIN";
      const hasReportsAccess = user.role === "MARVELOCK_ADMIN";
      setUserAccess({
        hasOrganisationsAccess,
        hasNotificationsAccess,
        hasServiceOrdersAccess,
        hasLocationsAccess,
        hasLockersAccess,
        hasUsersAccess,
        hasReportsAccess,
        hasOnboardingCodesAccess,
      });
    }
  }, [user]);

  return userAccess;
}
