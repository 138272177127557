import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IconButton from "@mui/material/IconButton";

import { Col, Row } from "../../../styles/layout";
import { Text } from "../../../styles/typography";
import { useMemo } from "react";

import { ClickableCard } from "../../ClickableCard";
import { useComponents } from "../../../hooks/useComponents";
import HallnewsComponent from "../../../entities/HallnewsComponent";
import { useTranslation } from "react-i18next";
import Dropdown from "../../Dropdown";

interface OwnProps {
  organisationIds: string[];
  selectedComponents: HallnewsComponent.Type[];
  onChange: (e: HallnewsComponent.Type[]) => void;
}

const ComponentsSelector = ({
  organisationIds,
  selectedComponents,
  onChange,
}: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();

  const { components } = useComponents(0, 100, organisationIds);

  const availableComponents = useMemo(
    () =>
      components.filter(
        (comp: any) => !selectedComponents.find((c) => c.id === comp.id)
      ),
    [components, selectedComponents]
  );

  return (
    <>
      <Col>
        <Dropdown
          items={availableComponents.map((comp: HallnewsComponent.Type) => ({
            label: comp.label,
            value: comp.label,
          }))}
          placeholder={
            availableComponents.length > 0
              ? getTranslationByLabel("user-dialog-widgets-prompt")
              : getTranslationByLabel("hallnews-components-none")
          }
          onChange={(v: string | undefined) => {
            onChange([
              ...selectedComponents,
              components.find((o: HallnewsComponent.Type) => o.label === v)!,
            ]);
          }}
          triggerClassList="h-14"
        />
        <Row mt="2" flexWrap="wrap">
          {selectedComponents.map((comp) => (
            <ClickableCard
              key={comp.id}
              onClick={() =>
                onChange(
                  selectedComponents.filter(
                    (c: HallnewsComponent.Type) => c.id !== comp.id
                  )
                )
              }
            >
              <Row
                p="3"
                m="1"
                bg="element"
                borderColor="elementActive"
                borderWidth="1px"
                borderStyle="solid"
                borderRadius="1"
                mr="3"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Text fontSize="16px" fontWeight="600" color="textHigh" mr="4">
                  {comp.label}
                </Text>
                <IconButton aria-label="close" color="primary">
                  <FontAwesomeIcon
                    icon={faClose}
                    size="xs"
                    width={14}
                    height={14}
                  />
                </IconButton>
              </Row>
            </ClickableCard>
          ))}
        </Row>
      </Col>
    </>
  );
};

export default ComponentsSelector;
