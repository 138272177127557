import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import "./style.scss";
import Input from "../Input";
import { Col } from "../../styles/layout";
import { Text } from "../../styles/typography";
import { motion } from "framer-motion";
import { useSession } from "../../hooks/SessionProvider";
import { Button } from "../ui/button";

interface UserLoginDetails {
  email: string;
}

const EmailForm = ({ onComplete }: { onComplete: (e: string) => void }) => {
  const { t: getTranslationByLabel } = useTranslation();
  const { requestCode } = useSession();
  const [error, setError] = useState<string | undefined>();

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<UserLoginDetails>({
    mode: "all",
  });

  const submit = async (data: UserLoginDetails) => {
    try {
      setError(undefined);
      await requestCode(data.email);
      onComplete(data.email);
    } catch (e: any) {
      console.error(e);
      setError("User does not exist");
    }
  };
  return (
    <motion.div
      initial={{ x: 0 }}
      animate={{ x: 0 }}
      exit={{ x: -400 }}
      transition={{
        type: "spring",
        duration: 0.2,
        stiffness: 450,
        damping: 35,
      }}
    >
      <Col alignItems={"stretch"}>
        <Col flexGrow="1" py="32px" alignItems="stretch">
          <Text fontSize="24px" fontWeight="600" color="mvl.primary" mb="24px">
            {getTranslationByLabel("auth-email-title")}
          </Text>
          <form onSubmit={handleSubmit(submit)}>
            <Col mb="44px" alignItems="stretch">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    label="Email"
                    type="email"
                    containerStyle={{ marginBottom: "16px" }}
                    {...field}
                  />
                )}
              />

              {error && (
                <Typography variant="caption" color="error">
                  {error}
                </Typography>
              )}
            </Col>
            <Button
              className="w-full"
              disabled={!isDirty || !isValid}
              type="submit"
            >
              {getTranslationByLabel("auth-email-button")}
            </Button>
          </form>
        </Col>
      </Col>
    </motion.div>
  );
};
export default EmailForm;
