import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";

import Input from "../../../components/Input";
import { useEffect, useState } from "react";
import {
  deleteContent,
  update as updateContent,
  create as createContent,
} from "../../../services/contents";
import HallnewsComponent from "../../../entities/HallnewsComponent";
import HallnewsComponentContent from "../../../entities/HallnewsComponentContent";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { Label } from "../../../components/Label";
import Textarea from "../../../components/Textarea";
import { DatePicker } from "../../../components/ui/date-picker";
import { startOfDay } from "date-fns";
import {
  TimePicker,
  TimePickerSegment,
  TimePickerSeparator,
} from "../../../components/ui/time-picker";
import DeleteConfirmationDialog from "../../../common/DeleteConfirmationDialog";

interface OwnProps {
  open: boolean;
  onClose: () => void;
  component?: HallnewsComponent.Type;
  content?: HallnewsComponentContent.Type;
}

interface FormData {
  id: number;
  title: string;
  payload: string;
  type: string;
  validFrom: Date;
  validTo: Date;
}

const contentToFormData = (content?: HallnewsComponentContent.Type) => {
  if (!content) return { type: "notification" };
  const { title, content: payload } = JSON.parse(content.payload);

  return {
    id: content.id,
    title,
    payload,
    type: content.type,
    validFrom: new Date(content.validFrom),
    validTo: new Date(content.validTo),
  };
};

const ContentDialog = ({ open, onClose, content, component }: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const { control, handleSubmit, reset, watch } = useForm<FormData>({
    defaultValues: contentToFormData(content),
  });

  const validFrom = watch("validFrom");
  const validTo = watch("validTo");

  useEffect(() => {
    reset(contentToFormData(content));
  }, [content, reset]);

  const submit = async (data: FormData) => {
    try {
      const payload = JSON.stringify({
        title: data.title,
        content: data.payload,
      });

      const { id, validFrom, validTo } = data;

      if (id) {
        await updateContent({
          id,
          type: "notification",
          validFrom,
          validTo,
          payload,
        });
      } else {
        await createContent({
          component,
          type: "notification",
          validFrom,
          validTo,
          payload,
        });
      }
      onClose();
    } catch (e: any) {
      console.error(e);
    }
  };

  const handleDelete = async () => {
    try {
      if (!content) return;
      console.log("Deleting content");
      await deleteContent(content.id!);
      onClose();
    } catch (e: any) {
      console.error(e);
      console.error(e);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {!!content
                ? getTranslationByLabel("hallnews-content-update-header")
                : getTranslationByLabel("hallnews-content-new-header")}
            </DialogTitle>
            <DialogDescription>
              {!!content
                ? getTranslationByLabel("hallnews-content-update-description")
                : getTranslationByLabel("hallnews-content-new-description")}
            </DialogDescription>
          </DialogHeader>

          <Controller
            name="title"
            rules={{ required: "Title is required" }}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                label={getTranslationByLabel("hallnews-content-new-title")}
                value={field.value}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="payload"
            rules={{ required: "Content is required" }}
            control={control}
            render={({ field, fieldState }) => (
              <Textarea
                label="Message"
                maxLength={255}
                spellCheck
                rows={1}
                value={field.value}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="validFrom"
            control={control}
            rules={{ required: "Start date is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="space-y-1">
                <Label
                  label={getTranslationByLabel(
                    "hallnews-content-new-validFrom"
                  )}
                  error={!!error}
                />
                <div className="flex w-full items-end space-x-2 ">
                  <div className="flex-grow">
                    <DatePicker
                      date={field.value}
                      onChange={field.onChange}
                      fromDate={startOfDay(new Date())}
                    />
                  </div>
                  <TimePicker
                    refDate={validFrom ?? new Date()}
                    onChange={field.onChange}
                    value={field.value}
                    className="w-fit"
                  >
                    <TimePickerSegment segment={"hours"} placeholder="00" />
                    <TimePickerSeparator>:</TimePickerSeparator>
                    <TimePickerSegment segment={"minutes"} placeholder="00" />
                  </TimePicker>
                </div>
                {!!error && (
                  <p className="text-xs text-destructive">{error.message}</p>
                )}
              </div>
            )}
          />
          <Controller
            name="validTo"
            control={control}
            rules={{ required: "End date is required" }}
            render={({ field, fieldState: { error } }) => (
              <div className="space-y-1">
                <Label
                  label={getTranslationByLabel("hallnews-content-new-validTo")}
                  error={!!error}
                />

                <div className="flex w-full items-end space-x-2 ">
                  <div className="flex-grow">
                    <DatePicker
                      date={field.value}
                      onChange={field.onChange}
                      fromDate={startOfDay(new Date())}
                    />
                  </div>
                  <TimePicker
                    refDate={validTo ?? new Date()}
                    onChange={field.onChange}
                    value={field.value}
                    className="w-fit"
                  >
                    <TimePickerSegment segment={"hours"} placeholder="00" />
                    <TimePickerSeparator>:</TimePickerSeparator>
                    <TimePickerSegment segment={"minutes"} placeholder="00" />
                  </TimePicker>
                </div>
                {!!error && (
                  <p className="text-xs text-destructive">{error.message}</p>
                )}
              </div>
            )}
          />

          <DialogFooter>
            {content && (
              <Button
                variant="destructive-outline"
                onClick={() => setShowDeleteDialog(true)}
              >
                {getTranslationByLabel("delete")}
              </Button>
            )}
            <Button variant="default" onClick={handleSubmit(submit)}>
              {getTranslationByLabel("save")}
            </Button>
          </DialogFooter>
        </DialogContent>
        <DeleteConfirmationDialog
          open={showDeleteDialog}
          // disabled={isSubmitting}
          onConfirm={handleDelete}
          onClose={() => {
            setShowDeleteDialog(false);
          }}
        />
      </Dialog>
    </>
  );
};

export default ContentDialog;
