import { useCallback, useEffect, useMemo, useState } from "react";
import HallnewsComponentPlacement from "../../../entities/HallnewsComponentPlacement";
import Layout from "../../../entities/Layout";
import { deletePlacement, update } from "../../../services/placements";
import { useTranslation } from "react-i18next";
import { CardDescription, CardTitle } from "../../../components/ui/card";
import { Label } from "../../../components/Label";
import { CountPicker } from "../../../components/CountPicker";
import { Button } from "../../../components/ui/button";

interface OwnProps {
  layout: Layout.Type;
  placement: HallnewsComponentPlacement.Type;
  onSave: () => void;
  onDelete: () => void;
}

interface Data {
  gridRow?: number;
  gridCol?: number;
  gridRowSpan?: number;
  gridColSpan?: number;
}

const PlacementConfig = ({ layout, placement, onSave, onDelete }: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const [gridRow, setGridRow] = useState<number>(placement.gridRow + 1);
  const [gridCol, setGridCol] = useState<number>(placement.gridCol + 1);
  const [gridRowSpan, setGridRowSpan] = useState<number>(placement.gridRowSpan);
  const [gridColSpan, setGridColSpan] = useState<number>(placement.gridColSpan);

  const handleUpdate = useCallback(
    async (data?: Data) => {
      try {
        const response = await update({
          id: placement.id,
          gridRow: gridRow - 1,
          gridCol: gridCol - 1,
          gridRowSpan,
          gridColSpan,
          ...data,
        });
        console.log(response);
        onSave();
      } catch (e: any) {
        setError(e.toString());
        console.error(e);
      }
    },
    [gridRow, gridCol, gridRowSpan, gridColSpan, placement.id, onSave]
  );

  const handleDelete = async () => {
    try {
      await deletePlacement(placement.id!);
      onSave();
    } catch (e: any) {
      setError(e.toString());
      console.error(e);
    }
  };

  const maxRows = useMemo(() => layout.gridRows, [layout]);
  const maxCols = useMemo(() => layout.gridCols, [layout]);
  const maxRowSpan = useMemo(() => maxRows - gridRow + 1, [maxRows, gridRow]);
  const maxColSpan = useMemo(() => maxCols - gridCol + 1, [maxCols, gridCol]);

  useEffect(() => {
    if (gridRowSpan > maxRowSpan) {
      setGridRowSpan(maxRowSpan);
    }
  }, [gridRowSpan, maxRowSpan]);

  useEffect(() => {
    if (gridColSpan > maxColSpan) {
      setGridColSpan(maxColSpan);
    }
  }, [gridColSpan, maxColSpan]);

  return (
    <>
      <div className="flex flex-col space-y-4 pb-6 rounded border p-4 max-w-xl">
        <div className="space-y-0">
          <CardTitle className="text-lg">
            {placement.component?.label}
          </CardTitle>
          <CardDescription>
            Modifier la position et la taille du composant
          </CardDescription>
        </div>
        <div>
          <Label label={getTranslationByLabel("hallnews-placement-row")} />
          <CountPicker
            max={maxRows}
            value={gridRow}
            onChange={(v: number) => {
              setGridRow(v);
              handleUpdate({ gridRow: v - 1 });
            }}
          />
        </div>
        <div>
          <Label label={getTranslationByLabel("hallnews-placement-col")} />
          <CountPicker
            max={maxCols}
            value={gridCol}
            onChange={(v: number) => {
              setGridCol(v);
              handleUpdate({ gridCol: v - 1 });
            }}
          />
        </div>
        <div>
          <Label label={getTranslationByLabel("hallnews-placement-height")} />
          <CountPicker
            max={maxRowSpan}
            value={gridRowSpan}
            onChange={(v: number) => {
              setGridRowSpan(v);
              handleUpdate({ gridRowSpan: v });
            }}
          />
        </div>
        <div>
          <Label label={getTranslationByLabel("hallnews-placement-width")} />
          <CountPicker
            max={maxColSpan}
            value={gridColSpan}
            onChange={(v: number) => {
              setGridColSpan(v);
              handleUpdate({ gridColSpan: v });
            }}
          />
        </div>

        {error && (
          <div className="text-red-500 bg-red-100 p-3 rounded-md">{error}</div>
        )}

        <div className="pt-4">
          <Button
            variant="destructive-outline"
            size="sm"
            onClick={handleDelete}
          >
            {getTranslationByLabel("delete")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PlacementConfig;
