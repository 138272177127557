import { BsEnvelope } from "react-icons/bs";
import { FaUserTag } from "react-icons/fa6";
import { useSession } from "../hooks/SessionProvider";
import { User } from "../entities";
import { Button } from "./ui/button";
import { useTranslation } from "react-i18next";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./ui/hover-card";
import { SETTINGS_PATH } from "../routes";
import { LucideSettings } from "lucide-react";
import { Avatar, AvatarFallback } from "./ui/avatar";

const TopBar = () => {
  const { user } = useSession();

  return (
    <div className=" flex min-h-[72px] w-full items-center justify-between border-b-2 border-b-border px-8 ">
      <div className=""></div>
      {user && (
        <HoverCard openDelay={100} closeDelay={100}>
          <HoverCardTrigger>
            <div className="flex  items-center space-x-4">
              <Avatar>
                <AvatarFallback className="bg-accent text-primary">{`${user?.firstName?.[0]}${user?.lastName?.[0]}`}</AvatarFallback>
              </Avatar>
              <div className="cursor-pointer">{`${user?.firstName} ${user?.lastName}`}</div>
            </div>
          </HoverCardTrigger>
          <HoverCardContent side="left" className="w-fit">
            <Menu user={user} />
          </HoverCardContent>
        </HoverCard>
      )}
    </div>
  );
};

export default TopBar;

const Menu = ({ user }: { user: User.Self }) => {
  const { t: getTranslationByLabel } = useTranslation();
  const { signOut } = useSession();
  return (
    <div className=" flex w-fit   flex-col items-stretch space-y-4 bg-white p-4">
      <div className="flex flex-col items-stretch space-y-1">
        <div className="flex items-center space-x-2  ">
          <div>
            <BsEnvelope size={16} />
          </div>
          <div className="text-sm font-normal ">{user.email}</div>
        </div>
        <div className="flex items-center space-x-2  ">
          <div>
            <FaUserTag size={16} />
          </div>
          <div className="text-sm font-normal ">
            {getTranslationByLabel(user.role)}
          </div>
        </div>
      </div>
      <a href={SETTINGS_PATH}>
        <div className="flex items-center space-x-2  text-slate-800">
          <div>
            <LucideSettings size={16} />
          </div>
          <div className="text-sm font-normal ">{"Paramètres de compte"}</div>
        </div>
      </a>

      <Button size="sm" variant="destructive-outline" onClick={signOut}>
        {getTranslationByLabel("sign-out")}
      </Button>
    </div>
  );
};
