import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { SortDirection } from "../filters";
import { useOrganisations } from "../hooks/useOrganisations";
import Dropdown, { DropDownItem } from "./Dropdown";
import { Button } from "./ui/button";
import { LucidePlus } from "lucide-react";
import { useLocations } from "../hooks/useLocations";
import { useSession } from "../hooks/SessionProvider";
import { User } from "../entities";

export enum FilterKeys {
  Organisation = "organisation_id",
  Location = "location_id",
  Building = "building",
  Role = "role",
  SortDirection = "sortDirection",
}

export interface Filters {
  organisationId?: string;
  locationId?: string;
  role?: User.UserType;
  sortDirection?: SortDirection;
}

interface OwnProps {
  onCreateClick?: () => void;
  withCreateButton?: boolean;
  showLocations?: boolean;
  showRole?: boolean;
  onChange?: (filter: Filters) => void;
}
const ItemsFilter = ({
  onCreateClick,
  withCreateButton,
  showLocations,
  showRole,
  onChange,
}: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  const location = useLocation();
  const { user: selfUser } = useSession();

  const history = useHistory();

  const [sortDirection, setSortDirection] = useState<string | undefined>(
    "DESC"
  );

  const [selectedLocationId, setSelectedLocationId] = useState<string>();

  const [selectedOrganisationId, setSelectedOrganisationId] = useState<
    string | undefined
  >();

  const [role, setRole] = useState<User.UserType>();

  const { organisations } = useOrganisations(0, 100);

  const { locations } = useLocations(
    0,
    1000,
    {
      organization: selectedOrganisationId,
    },
    !showLocations
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sort = params.get(FilterKeys.SortDirection);
    setSortDirection(sort ?? undefined);

    const orgId = params.get(FilterKeys.Organisation);
    setSelectedOrganisationId(orgId ?? undefined);

    const locationId = params.get(FilterKeys.Location);
    setSelectedLocationId(locationId ?? undefined);

    const role = params.get(FilterKeys.Role);
    setRole(role as User.UserType);
  }, [location.search]);

  const handleSort = useCallback(
    (value?: string) => {
      setSortDirection(value as SortDirection);
      const searchParams = new URLSearchParams(location.search);
      if (value === undefined) {
        searchParams.delete(FilterKeys.SortDirection);
      } else {
        if (searchParams.get(FilterKeys.SortDirection)) {
          searchParams.set(FilterKeys.SortDirection, value);
        } else {
          searchParams.append(FilterKeys.SortDirection, value);
        }
      }
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [history, location.pathname, location.search]
  );

  const handleOrganisation = useCallback(
    (value: string | undefined) => {
      setSelectedOrganisationId(value);
      const searchParams = new URLSearchParams(location.search);
      if (value === undefined) {
        setSelectedLocationId(undefined);
        searchParams.delete(FilterKeys.Organisation);
      } else {
        if (searchParams.get(FilterKeys.Organisation)) {
          searchParams.set(FilterKeys.Organisation, value);
        } else {
          searchParams.append(FilterKeys.Organisation, value);
        }
      }
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [history, location.pathname, location.search]
  );

  const handleLocation = useCallback(
    (value: string | undefined) => {
      setSelectedLocationId(value);
      const searchParams = new URLSearchParams(location.search);
      if (value === undefined) {
        searchParams.delete(FilterKeys.Location);
      } else {
        if (searchParams.get(FilterKeys.Location)) {
          searchParams.set(FilterKeys.Location, value);
        } else {
          searchParams.append(FilterKeys.Location, value);
        }
      }

      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [history, location.pathname, location.search]
  );

  const handleRole = useCallback(
    (value: string | undefined) => {
      setRole(value as User.UserType);
      const searchParams = new URLSearchParams(location.search);
      if (value === undefined) {
        searchParams.delete(FilterKeys.Role);
      } else {
        if (searchParams.get(FilterKeys.Role)) {
          searchParams.set(FilterKeys.Role, value);
        } else {
          searchParams.append(FilterKeys.Role, value);
        }
      }
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [history, location.pathname, location.search]
  );

  const roleItems: DropDownItem[] = useMemo(() => {
    if (!selfUser) {
      return [];
    }
    const result = [];
    if (selfUser.role === "SITE_MANAGER") {
      result.push({
        value: "BUILDING_MANAGER",
        label: getTranslationByLabel("building-managers"),
      });
    } else if (selfUser.role === "MARVELOCK_ADMIN") {
      result.push({
        value: "MARVELOCK_ADMIN",
        label: getTranslationByLabel("administrator"),
      });
      result.push({
        value: "BUILDING_MANAGER",
        label: getTranslationByLabel("building-managers"),
      });
      result.push({
        value: "SITE_MANAGER",
        label: getTranslationByLabel("site-managers"),
      });
    }
    result.push({
      value: "RESIDENT",
      label: getTranslationByLabel("residents"),
    });
    return result;
  }, [getTranslationByLabel, selfUser]);

  useEffect(() => {
    onChange?.({
      organisationId: selectedOrganisationId,
      locationId: selectedLocationId,
      role,
      sortDirection: sortDirection as SortDirection,
    });
  }, [
    selectedLocationId,
    selectedOrganisationId,
    sortDirection,
    role,
    onChange,
  ]);

  return (
    <div className="flex items-center justify-end space-x-4 my-4">
      <div className="flex items-center space-x-2">
        {showRole && (
          <Dropdown
            items={roleItems}
            value={role}
            onChange={handleRole}
            triggerClassList="w-64"
            placeholder="Rôle"
          />
        )}
        <Dropdown
          items={organisations.map((org) => ({
            value: org.id!,
            label: org.name,
          }))}
          value={selectedOrganisationId}
          onChange={handleOrganisation}
          triggerClassList="w-64"
          placeholder="Organisation"
        />
        {showLocations && (
          <Dropdown
            items={locations.map((loc) => ({
              value: loc.id!,
              label: loc.name,
            }))}
            value={selectedLocationId}
            onChange={handleLocation}
            triggerClassList="w-64"
            placeholder="Location"
          />
        )}
        <Dropdown
          items={["ASC", "DESC"].map((v) => ({
            value: v,
            label: v,
          }))}
          value={sortDirection}
          initialValue={sortDirection}
          onChange={(v) => handleSort(v)}
          placeholder="Tri"
        />
      </div>

      {withCreateButton && onCreateClick && (
        <Button onClick={onCreateClick} variant="default" className="space-x-2">
          <LucidePlus size={16} />
          <span>{getTranslationByLabel("create-new")}</span>
        </Button>
      )}
    </div>
  );
};

export default ItemsFilter;
