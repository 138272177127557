import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import {
  CreateOnboardingCodeDTO,
  UpdateOnboardingCodeDTO,
} from "../../services/onboarding-codes/onboarding-codes.dto";
import { DialogDescription, DialogHeader, DialogTitle } from "../ui/dialog";
import { Checkbox } from "../ui/checkbox";
import { Button } from "../ui/button";
import { OnboardingCode } from "../../entities/OnboardingCode";
import {
  createOnboardingCodeRequest,
  deleteOnboardingCodeRequest,
  updateOnboardingCodeRequest,
} from "../../services/onboarding-codes/onboarding-codes.service";
import LocationsPicker from "./locations-picker";
import { Input } from "../ui/input";
import ColorPicker from "../ColorPicker";

interface OnboardingCodeFormProps {
  onboardingCode?: OnboardingCode;
  onUpdate: () => void;
  onCancel: () => void;
}

const codeToFormDTO = (
  code?: OnboardingCode
): CreateOnboardingCodeDTO | UpdateOnboardingCodeDTO => {
  if (!code) {
    return {
      locationIds: [],
      organizationId: "",
      lockerEmailNotificationsEnabled: false,
      emailNotificationsEnabled: false,
      mobileNotificationsEnabled: false,
      lockerMobileNotificationsEnabled: false,
      smsNotificationsEnabled: false,
      smsLockerNotificationsEnabled: false,
      tagName: "tag",
      tagColor: "#000000",
    };
  }
  return {
    id: code.id,
    locationIds: code.locations.map((location) => location.id),
    lockerEmailNotificationsEnabled: code.lockerEmailNotificationsEnabled,
    emailNotificationsEnabled: code.emailNotificationsEnabled,
    mobileNotificationsEnabled: code.mobileNotificationsEnabled,
    lockerMobileNotificationsEnabled: code.lockerMobileNotificationsEnabled,
    smsNotificationsEnabled: code.smsNotificationsEnabled,
    smsLockerNotificationsEnabled: code.smsLockerNotificationsEnabled,
    tagName: code.tagName,
    tagColor: code.tagColor,
  };
};

export const OnboardingCodeForm: React.FC<OnboardingCodeFormProps> = ({
  onboardingCode,
  onUpdate,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<
    CreateOnboardingCodeDTO | UpdateOnboardingCodeDTO
  >({
    defaultValues: codeToFormDTO(onboardingCode),
  });

  const handleDelete = async () => {
    try {
      if (!onboardingCode) {
        return;
      }
      await deleteOnboardingCodeRequest(onboardingCode.id);
      onUpdate();
    } catch (e) {}
  };

  const onFormSubmit = async (
    data: CreateOnboardingCodeDTO | UpdateOnboardingCodeDTO
  ) => {
    try {
      if (!!onboardingCode) {
        await updateOnboardingCodeRequest(
          onboardingCode.id,
          data as UpdateOnboardingCodeDTO
        );
      } else {
        await createOnboardingCodeRequest(data as CreateOnboardingCodeDTO);
      }
      onUpdate();
    } catch (e) {}
  };

  return (
    <div className="space-y-6">
      <DialogHeader>
        <DialogTitle>
          {onboardingCode
            ? t("edit_onboarding_code")
            : t("create_onboarding_code")}
        </DialogTitle>
        <DialogDescription>{t("onboarding_code_info")}</DialogDescription>
      </DialogHeader>

      <form onSubmit={handleSubmit(onFormSubmit)} className="space-y-4">
        <div className="flex flex-col gap-2">
          <label
            htmlFor="locationId"
            className="block text-sm font-semibold text-gray-700"
          >
            Emplacement(s)
          </label>
          <Controller
            name="locationIds"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <div>
                <LocationsPicker
                  selectedLocationIds={field.value ?? []}
                  onChange={field.onChange}
                />
              </div>
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <h3 className="text-lg font-semibold mb-2">Locker</h3>
            <div className="space-y-2">
              <Controller
                name="lockerEmailNotificationsEnabled"
                control={control}
                render={({ field }) => (
                  <div className="flex items-center">
                    <Checkbox
                      id="lockerEmailNotificationsEnabled"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <label
                      htmlFor="lockerEmailNotificationsEnabled"
                      className="ml-2 text-sm"
                    >
                      {t("email_notifications")}
                    </label>
                  </div>
                )}
              />
              <Controller
                name="lockerMobileNotificationsEnabled"
                control={control}
                render={({ field }) => (
                  <div className="flex items-center">
                    <Checkbox
                      id="lockerMobileNotificationsEnabled"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <label
                      htmlFor="lockerMobileNotificationsEnabled"
                      className="ml-2 text-sm"
                    >
                      {t("mobile_notifications")}
                    </label>
                  </div>
                )}
              />
              <Controller
                name="smsLockerNotificationsEnabled"
                control={control}
                render={({ field }) => (
                  <div className="flex items-center">
                    <Checkbox
                      id="smsLockerNotificationsEnabled"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <label
                      htmlFor="smsLockerNotificationsEnabled"
                      className="ml-2 text-sm"
                    >
                      {t("sms_notifications")}
                    </label>
                  </div>
                )}
              />
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-2">System</h3>
            <div className="space-y-2">
              <Controller
                name="emailNotificationsEnabled"
                control={control}
                render={({ field }) => (
                  <div className="flex items-center">
                    <Checkbox
                      id="emailNotificationsEnabled"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <label
                      htmlFor="emailNotificationsEnabled"
                      className="ml-2 text-sm"
                    >
                      {t("email_notifications")}
                    </label>
                  </div>
                )}
              />
              <Controller
                name="mobileNotificationsEnabled"
                control={control}
                render={({ field }) => (
                  <div className="flex items-center">
                    <Checkbox
                      id="mobileNotificationsEnabled"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <label
                      htmlFor="mobileNotificationsEnabled"
                      className="ml-2 text-sm"
                    >
                      {t("mobile_notifications")}
                    </label>
                  </div>
                )}
              />
              <Controller
                name="smsNotificationsEnabled"
                control={control}
                render={({ field }) => (
                  <div className="flex items-center">
                    <Checkbox
                      id="smsNotificationsEnabled"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <label
                      htmlFor="smsNotificationsEnabled"
                      className="ml-2 text-sm"
                    >
                      {t("sms_notifications")}
                    </label>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-2">
            <label
              htmlFor="tagName"
              className="block text-sm font-semibold text-gray-700"
            >
              Tag Name
            </label>
            <Controller
              name="tagName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <div>
                  <Input
                    id="tagName"
                    value={field.value}
                    onChange={field.onChange}
                  />
                </div>
              )}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              htmlFor="tagColor"
              className="block text-sm font-semibold text-gray-700"
            >
              Tag Color
            </label>
            <Controller
              name="tagColor"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <div>
                  <ColorPicker
                    color={field.value ?? "#000000"}
                    onChange={field.onChange}
                  />
                </div>
              )}
            />
          </div>
        </div>

        <div className="flex justify-end space-x-4 pt-6">
          <Button type="button" variant="outline" onClick={onCancel}>
            {t("button_cancel")}
          </Button>
          {!!onboardingCode && (
            <Button
              type="button"
              variant="destructive-outline"
              onClick={handleDelete}
            >
              {t("button_delete")}
            </Button>
          )}
          <Button type="submit">
            {onboardingCode ? t("button_update") : t("button_create")}
          </Button>
        </div>
      </form>
    </div>
  );
};
