import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useMemo,
  useCallback,
  useContext,
  useRef,
} from "react";
import { User } from "../entities";
import { LoginResponse, login, token } from "../services/auth";
import { useSelfUser } from "./useSelfUser";
import Cookies from "js-cookie";
import { useRouter } from "./useRouter";
import toast from "react-hot-toast";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router";

interface SessionContextProps {
  user: User.Self | undefined;
  isLoading: boolean;
  authenticated: boolean;
  requestCode: (email: string) => Promise<void>;
  signIn: (otp: string) => void;
  signOut: () => Promise<void>;
  setEmail: (e: string) => void;
  onboardingCode: string | undefined;
  setOnboardingCode: (code: string) => void;
}

export const SessionContext = createContext<SessionContextProps>({
  user: undefined,
  isLoading: false,
  requestCode: (e: string) => Promise.reject(),
  signIn: (e: string) => Promise.reject(),
  authenticated: false,
  signOut: () => Promise.reject(),
  setEmail: (e: string) => {},
  onboardingCode: undefined,
  setOnboardingCode: (e: string) => {},
});

const SessionProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const history = useHistory();

  const [email, setEmail] = useState<string | undefined>();
  const [onboardingCode, setOnboardingCode] = useState<string>();
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { user } = useSelfUser(authenticated);
  const initialised = useRef<boolean>();

  const requestCode = async (userEmail: string) => {
    setEmail(userEmail);
    await login({ email: userEmail });
  };

  const handleAuthResponse = (response: LoginResponse) => {
    Cookies.set("mvl.access-token", response.accessToken, {
      expires: new Date(response.expiresAt),
      secure: true,
      sameSite: "Lax",
    });
    Cookies.set("mvl.refresh-token", response.refreshToken, {
      expires: new Date(response.refreshExpiresAt),
      secure: true,
      sameSite: "Lax",
    });
  };
  const signIn = useCallback(
    async (otp: string) => {
      if (!email) {
        console.error("Missing user email");
      } else {
        const response = await login({ email, otp });
        handleAuthResponse(response);

        setAuthenticated(true);
        router.push("/app");
      }
    },
    [email, router]
  );

  const signOut = useCallback(async () => {
    // Perform logout logic
    setEmail(undefined);
    Cookies.remove("mvl.access-token");
    Cookies.remove("mvl.refresh-token");
    setAuthenticated(false);
    history.push("/login");
  }, [history]);

  const checkAuthentication = useCallback(async () => {
    console.log("checkAuthentication");
    if (!initialised.current) {
      setIsLoading(true);
    }

    const accessToken = Cookies.get("mvl.access-token");
    const refreshToken = Cookies.get("mvl.refresh-token");

    try {
      if (accessToken) {
        const decodedToken = jwt_decode(accessToken) as any;
        const expiryDate = new Date(decodedToken.exp * 1000);

        if (expiryDate > new Date()) {
          // Access token is not expired
          setAuthenticated(true);
        } else if (refreshToken) {
          // Access token is expired, refresh it using the refresh token
          setIsLoading(true);
          Cookies.remove("mvl.access-token");
          const response = await token();
          handleAuthResponse(response);

          setAuthenticated(true);
        } else {
          // Access token is expired and refresh token is not present
          console.error("Authentication error: Refresh token expired");
          toast.error("Authentication error.  Please log in again");
          throw new Error("Expired session");
        }
      } else if (refreshToken) {
        // Access token is not present but refresh token is present
        const decodedToken = jwt_decode(refreshToken) as any;
        const expiryDate = new Date(decodedToken.exp * 1000);

        if (expiryDate > new Date()) {
          setIsLoading(true);
          const response = await token();
          handleAuthResponse(response);

          setAuthenticated(true);
        } else {
          // Refresh token is expired
          console.error("Authentication error: Refresh token expired");
          toast.error("Authentication error.  Please log in again");
          throw new Error("Refresh token expired");
        }
      } else {
        // Neither access token nor refresh token is present
        throw new Error("No tokens found");
      }
    } catch (error) {
      if (router.pathname !== "/login" && router.pathname !== "/signup") {
        console.error("Authentication error", error);
        setAuthenticated(false);
        Cookies.remove("mvl.access-token");
        Cookies.remove("mvl.refresh-token");
        router.push("/login");
      }
    }

    initialised.current = true;
    setIsLoading(false);
  }, [router]);

  // Check auth at interval
  useEffect(() => {
    const tokenExpirationTime = 2 * 1000;

    const timer = setInterval(() => {
      checkAuthentication();
    }, tokenExpirationTime);

    return () => clearTimeout(timer);
  }, [checkAuthentication]);

  // Check auth on focus
  useEffect(() => {
    const handleWindowFocus = () => {
      checkAuthentication();
    };
    window.addEventListener("focus", handleWindowFocus);

    return () => {
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, [checkAuthentication]);

  const contextValues = useMemo<SessionContextProps>(
    () => ({
      user,
      isLoading,
      requestCode,
      signIn,
      signOut,
      authenticated,
      setEmail,
      onboardingCode,
      setOnboardingCode,
    }),
    [authenticated, isLoading, signIn, signOut, user, onboardingCode]
  );

  return (
    <SessionContext.Provider value={contextValues}>
      {children}
    </SessionContext.Provider>
  );
};

function useSession(): SessionContextProps {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
}

export { SessionProvider, useSession };
