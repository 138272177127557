import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocations } from "../../hooks/useLocations";
import { useSession } from "../../hooks/SessionProvider";
import { ClickableCard } from "../ClickableCard";
import Dropdown from "../Dropdown";
import { LucideXCircle } from "lucide-react";

interface LocationsPickerProps {
  selectedLocationIds: string[];
  onChange: (locationIds: string[]) => void;
}

const LocationsPicker: React.FC<LocationsPickerProps> = ({
  selectedLocationIds,
  onChange,
}) => {
  const { t: getTranslationByLabel } = useTranslation();
  const { locations } = useLocations(0, 100);
  const { user: selfUser } = useSession();

  const userLocationIds =
    selfUser?.locationACLs.map((acl) => acl.location.id) ?? [];

  const availableLocations = useMemo(
    () =>
      locations.filter(
        (location) => !selectedLocationIds.includes(location.id)
      ),
    [locations, selectedLocationIds]
  );

  const handleLocationAdd = (locationId: string) => {
    onChange([...selectedLocationIds, locationId]);
  };

  const handleLocationRemove = (locationId: string) => {
    onChange(selectedLocationIds.filter((id) => id !== locationId));
  };

  return (
    <div className="space-y-0">
      <Dropdown
        items={availableLocations.map((location) => ({
          label: location.name,
          value: location.id,
        }))}
        placeholder={
          availableLocations.length > 0
            ? getTranslationByLabel("user-dialog-locations-prompt")
            : getTranslationByLabel("user-dialog-locations-none")
        }
        onChange={(value: string | undefined) => {
          if (value) handleLocationAdd(value);
        }}
        wrapperCss="h-14"
      />
      <div className="flex flex-wrap gap-2">
        {selectedLocationIds.map((locationId) => {
          const location = locations.find((l) => l.id === locationId);
          if (!location) return null;

          const isDisabled =
            selfUser?.role !== "BUILDING_MANAGER"
              ? false
              : !userLocationIds.includes(locationId);

          return (
            <ClickableCard
              key={locationId}
              onClick={() => handleLocationRemove(locationId)}
              disabled={isDisabled}
            >
              <div
                className={`flex items-center justify-between px-3 py-1.5 bg-input/30 border-primary/30 gap-2 border rounded-full text-xs ${
                  isDisabled ? "opacity-50" : ""
                }`}
              >
                <span className="text-xs font-semibold">{location.name}</span>
                {!isDisabled && <LucideXCircle size={16} />}
              </div>
            </ClickableCard>
          );
        })}
      </div>
    </div>
  );
};

export default LocationsPicker;
