import { OnboardingCode } from "../../entities/OnboardingCode";
import { OnboardingCodeForm } from "./form-onboarding-code";
import { Dialog, DialogContent } from "../ui/dialog";

interface ModalAcceptOfferProps {
  open: boolean;
  onClose: () => void;
  onboardingCode?: OnboardingCode;
  onUpdate: () => void;
}

export const ModalOnboardingCode: React.FC<ModalAcceptOfferProps> = ({
  open,
  onClose,
  onboardingCode,
  onUpdate,
}) => {
  const handleAccepted = () => {
    onUpdate();
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <OnboardingCodeForm
          onboardingCode={onboardingCode}
          onUpdate={handleAccepted}
          onCancel={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};
