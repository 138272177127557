import Organisation from "../../entities/Organisation";
import { useEffect, useState } from "react";
import { update } from "../../services/organisations";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Button } from "../ui/button";
import Input from "../Input";

interface OwnProps {
  open: boolean;
  onClose: () => void;
  organisation: Organisation.Type;
}

const ModalOrganisationUpdate = ({ open, onClose, organisation }: OwnProps) => {
  const [name, setName] = useState<string | null>(organisation.name);
  const [description, setDescription] = useState<string | undefined>(
    organisation.description
  );

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setName(organisation.name);
    setDescription(organisation.description);
  }, [organisation]);

  const handleUpdate = async () => {
    if (!name) {
      setError("Remplissez chaque champ");
    } else {
      try {
        await update({
          id: organisation.id,
          name,
          description,
        });
        onClose();
      } catch (e: any) {
        setError(e.toString());
        console.error(e);
      }
    }
  };

  const handleClose = () => {
    setName(null);
    setDescription(undefined);
    setError(null);
    onClose();
  };

  return (
    <>
      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {`Modifier l'organisation ${organisation.name}`}
            </DialogTitle>
            <DialogDescription>
              Gérer les informations de l'organisation
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4 pb-6">
            <Input
              label="Nom"
              value={name}
              onChange={(v: string) => {
                setError(null);
                setName(v);
              }}
              containerStyle={{ mb: "4" }}
            />
            <Input
              value={description}
              label="Description"
              onChange={(v: string) => {
                setError(null);
                setDescription(v);
              }}
              containerStyle={{ mb: "4" }}
            />
          </div>
          {error && (
            <div className="text-red-500 bg-red-100 p-3 rounded-md">
              {error}
            </div>
          )}
          <DialogFooter>
            <Button variant="outline" onClick={() => onClose()}>
              Annuler
            </Button>
            <Button variant="default" onClick={handleUpdate}>
              Enregistrer
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalOrganisationUpdate;
